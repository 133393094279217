import Vue from "vue";
import Vuex from "vuex";

import userReport from "@/components/viewBar/store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    XINYOU_SH_USER: {},
    balance: 0,
    promoteCoin: 0,
    newRecord:null,
  },
  mutations: {
    setUser(state, user = {}) {
      state.XINYOU_SH_USER = user;
    },
    // 剩余报告数
    setBalance(state, num = 0) {
      state.balance = (num / 100).toFixed(2);
    },
    setPromoteCoin(state, num = 0) {
      state.promoteCoin = Math.floor(num / 100);
    },
    setNewRecord(state, newRecord = null) {
      state.newRecord = newRecord;
    }
  },
  modules: {
    userReport
  }
});
